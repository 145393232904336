// 部门相关api
import request from '@/utils/request'

//上传
export function UploadImgs(data) {
    return request({
        url: 'UploadFile/UploadImgs',
        method: 'post',
        params: data
    })
}

// 应用信息
export function WeGetAppletInfo(query) {
    return request({
        url: '/Applet/WeGetAppletInfo',
        method: 'get',
        params: query
    })
}

// 联系我们
export function WeGetAppletOrganList(query) {
    return request({
        url: '/Organ/WeGetAppletOrganList',
        method: 'get',
        params: query
    })
}

// 组织机构给详情
export function WeGetAppletOrgan(query) {
    return request({
        url: '/Organ/WeGetAppletOrgan',
        method: 'get',
        params: query
    })
}

export function WeGetSuggestKindList(query) {
    return request({
        url: '/Suggest/WeGetSuggestKindList',
        method: 'get',
        params: query
    })
}
// 小程序获取获取小区分页列表
export function WeGetRegionPage(query) {
    return request({
        url: 'Region/WeGetRegionPage',
        method: 'get',
        params: query
    })
}
// 获取网格医信息列表
export function WxGetGridMedicinePage(query) {
    return request({
        url: 'GridMedicine/WxGetGridMedicinePage',
        method: 'get',
        params: query
    })
}
// export function createDept(data) {
//     return request({
//         url: '/dept/saveDeptInfo',
//         method: 'post',
//         data
//     })
// }